import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { AppSettings } from './app.globals';
import { PubSubService } from '@shared/services/pubsub.service';
import { AppInsightsService } from '@shared/services/app-insights.service';

@Component({
    selector: 'bh-app',
    templateUrl: 'app.component.html',
    // these are singletons and should NOT be "providers" anywhere else
    providers: [PubSubService, AppSettings]
})
export class AppComponent {
    constructor(private http: HttpClient, private router: Router, private appInsightsService: AppInsightsService) {
        if (!appInsightsService.isInitialized) {
            appInsightsService.init();
        }
    }
}
