import { AppFunctions } from './shared/app.functions';

export class AppSettings {
    public static get API_BASE_URL(): string {
        let apiUrl: string = sessionStorage.getItem('apiurl');

        if (AppFunctions.IsNullOrWhiteSpace(apiUrl)) {
            AppSettings.restoreMissingSessionStorageVariables();
            apiUrl = sessionStorage.getItem('apiurl');
        }

        return apiUrl;
    }
    public static get REPORTAPI_BASE_URL(): string {
        let reportingApiUrl: string = sessionStorage.getItem('apireportingurl');

        if (AppFunctions.IsNullOrWhiteSpace(reportingApiUrl)) {
            AppSettings.restoreMissingSessionStorageVariables();
            reportingApiUrl = sessionStorage.getItem('apireportingurl');
        }

        return reportingApiUrl;
    }
    public static get DOCPREP_API_BASE_URL(): string {
        let docPrepApiUrl: string = sessionStorage.getItem('docprepapiurl');

        if (AppFunctions.IsNullOrWhiteSpace(docPrepApiUrl)) {
            AppSettings.restoreMissingSessionStorageVariables();
            docPrepApiUrl = sessionStorage.getItem('docprepapiurl');
        }

        return docPrepApiUrl;
    }
    public static get HMDA_GeoCode_Lookup_URL(): string {
        let hmdaGeoCodeLookupUrl: string = sessionStorage.getItem('HMDAGeoCodeLookup');

        if (AppFunctions.IsNullOrWhiteSpace(hmdaGeoCodeLookupUrl)) {
            AppSettings.restoreMissingSessionStorageVariables();
            hmdaGeoCodeLookupUrl = sessionStorage.getItem('HMDAGeoCodeLookup');
        }

        return hmdaGeoCodeLookupUrl;
    }
    public static get STATUS_URL(): string {
        let statusUrl: string = sessionStorage.getItem('statusurl');

        if (AppFunctions.IsNullOrWhiteSpace(statusUrl) && window['nxg'] && window['nxg']['statusurl']) {
            AppSettings.restoreMissingSessionStorageVariables();
            statusUrl = sessionStorage.getItem('statusurl');
        }

        return statusUrl;
    }
    public static get HELP_URL(): string {
        let helpUrl: string = sessionStorage.getItem('helpurl');

        if (AppFunctions.IsNullOrWhiteSpace(helpUrl)) {
            AppSettings.restoreMissingSessionStorageVariables();
            helpUrl = sessionStorage.getItem('helpurl');
        }

        return helpUrl;
    }
    public static get UI_RELEASE_VERSION(): string {
        let uiReleaseVersion: string = sessionStorage.getItem('uiReleaseVersionDescription');

        if (AppFunctions.IsNullOrWhiteSpace(uiReleaseVersion)) {
            AppSettings.restoreMissingSessionStorageVariables();
            uiReleaseVersion = sessionStorage.getItem('uiReleaseVersionDescription');
        }

        return uiReleaseVersion;
    }
    public static get UI_BUILD_VERSION(): string {
        let uiBuildVersion: string = sessionStorage.getItem('uiBuildVersion');

        if (AppFunctions.IsNullOrWhiteSpace(uiBuildVersion)) {
            AppSettings.restoreMissingSessionStorageVariables();
            uiBuildVersion = sessionStorage.getItem('uiBuildVersion');
        }

        return uiBuildVersion;
    }
    public static get APP_INSIGHTS_CONNECTION_STRING(): string {
        let appInsightsConnectionString: string = sessionStorage.getItem('appInsightsConnectionString');

        if (AppFunctions.IsNullOrWhiteSpace(appInsightsConnectionString)) {
            AppSettings.restoreMissingSessionStorageVariables();
            appInsightsConnectionString = sessionStorage.getItem('appInsightsConnectionString');
        }

        return appInsightsConnectionString;
    }
    public static get USE_SKELETON_LOADING(): string {
        let useSkeletonLoading: string = sessionStorage.getItem('UseSkeletonLoading');

        if (AppFunctions.IsNullOrWhiteSpace(useSkeletonLoading)) {
            AppSettings.restoreMissingSessionStorageVariables();
            useSkeletonLoading = sessionStorage.getItem('UseSkeletonLoading');
        }

        return useSkeletonLoading;
    }
    private static restoreMissingSessionStorageVariables(): void {
        if (!window['nxg']) return;
        Object.keys(window['nxg'])
            .filter(key => !sessionStorage.getItem(key))
            .forEach(key => sessionStorage.setItem(key, window['nxg'][key]));
    }
}
