import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInsightsService } from '@shared/services/app-insights.service';
import { UserInfo } from '@shared/common/models/user-info.model';

@Injectable()
export class AppInsightsInterceptor implements HttpInterceptor {
    private CURRENT_USER: string = 'current_user';

    constructor(private appInsightsService: AppInsightsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appInsightsService.isConfigured) {
            let userInfo: UserInfo = null;
            let currentUserInfo: string = sessionStorage.getItem(this.CURRENT_USER);
            if (currentUserInfo) {
                userInfo = Object.assign(new UserInfo(), JSON.parse(currentUserInfo));
            }
            else {
                userInfo = new UserInfo();
            }
            let properties = {
                urlWithParams: req.urlWithParams,
                body: req.body,
                userId: userInfo.Id,
                userFullName: userInfo.FullName,
                userEmail: userInfo.Email,
                userFinancialInstitutionDomainName: userInfo.FinancialInstitutionDomainName,
                userIsSysAdmin: String(userInfo.IsSysAdmin),
                userIsSecurityAdmin: String(userInfo.IsSecurityAdmin),
                userIsBHCAdmin: String(userInfo.IsBHCAdmin),
                userSuperWriter: String(userInfo.SuperWriter),
                userSuperReader: String(userInfo.SuperReader)
            };
            this.appInsightsService.trackEvent({
                name: req.url
            }, properties);
        }
        return next.handle(req);
    }
}
