import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '@shared/models/user-model.model';
import { INavigationSection } from '@shared/services/navigation.service';
import { AuthRoute } from '../auth-route';
import { ClientSectionsSecurity } from '../common/security/client-sections-security.model';
import { SecurityObject } from '../common/security/security-object.model';
import { UserPermissions } from '../common/security/user-permissions.model';
import { UnificationAPIService } from './unification.api.service';
import { AppFunctions } from '../app.functions';
import { OpportunityDocumentSecurityModel } from '../models/opportunity-document-security.model';
import { IDbStyleRowSecurity, IRowSecurity } from '../../interfaces/row-security.interface';
import { DbStyleRowSecurityModel } from '../../models/row-security.model';

@Injectable()
export class ApplicationSecurityService {
	private url: string;
	private currentUser: string = 'current_user';

	get clientDetailNavigationSecurity(): ClientSectionsSecurity {
		return JSON.parse(this.baseService.currentUserClientSecurity);
	}

	get userPermissions(): UserPermissions {
		return this.baseService.parsedUserPermissions;
	}

    get allowBankerAdmin(): boolean {

        const otherSecurityObjectBhCodesAllowingAdminAccess: string[] = [
            'BANKER_MANAGE_ABANDONED_DRAFT_APPLICATIONS'
        ];

        return !!this.userPermissions.SectionPermissions
            ?.Banker
            ?.SecurityObjects
            ?.find(x => otherSecurityObjectBhCodesAllowingAdminAccess.includes(x.BhCode) && x.DeniedWriteAccess == 0);
    }

    get allowClientPortalAdmin(): boolean {

        const otherSecurityObjectBhCodesAllowingAdminAccess: string[] = [
            'CLIENT_PORTAL_USERS',
            'CLIENT_PORTAL_EMAIL_LOGIN',
            'CONFIGURE_EMAILS'
        ];

        return !!this.userPermissions.SectionPermissions
            ?.ClientPortal
            ?.SecurityObjects
            ?.find(x => otherSecurityObjectBhCodesAllowingAdminAccess.includes(x.BhCode) && x.DeniedReadAccess == 0);
    }

    constructor(private readonly baseService: UnificationAPIService) {
        this.url = this.baseService.apiURL();
    }

	public loadUserPermissions(): Observable<any> {
		let url: string = this.url + 'Security';
		url = url.replace('odata/', 'api/');
		return this.baseService.get(url)
			.pipe(map((result: any) => result));
	}

	public storeUserPermissions(value: UserPermissions) {

		this.baseService.currentUserPermissions = JSON.stringify(value);

		// Keeping this code to avoid a rewrite of existing functions
		var clientSections = value.SectionPermissions;
		this.baseService.currentUserClientSecurity = JSON.stringify(clientSections);
	}

	// Applies security and update/remove the section routes.
	public applySecurityOnSectionRoutes(securityObjects: SecurityObject[],
		navigationSections: INavigationSection[], sectionName: string, readOnlySectionRoute: AuthRoute) {

		let currentUser: UserModel = new UserModel();
		currentUser = JSON.parse(sessionStorage.getItem(this.currentUser));
		var sectionIndex = navigationSections.findIndex(x => x.displayText == sectionName);

		// Return if no section found
		if (sectionIndex == -1) {
			return;
		}

		// Super writer has all the permissions. Hence no changes in the route
		if (currentUser.SuperWriter) {
			return;
		}

		let writeAccessExists = securityObjects.find(x => x.DeniedWriteAccess == 0);
		let readAccessExists = securityObjects.find(x => x.DeniedReadAccess == 0);

		// Confirms if the individual user has no write permission, and is allowed to read
		if (currentUser.SuperReader || (!writeAccessExists && readAccessExists)) {
			navigationSections[sectionIndex].route = readOnlySectionRoute;
		}

		// Removes the section if no access defined
		else if (!currentUser.SuperReader && !readAccessExists) {
			navigationSections.splice(sectionIndex, 1);
		}
	}

	public applySecurityForDocumentsOnSectionRoutes(uploadedDocsSecurityObjects: SecurityObject[],
		electronicDocsSecurityObjects: SecurityObject[],
		navigationSections: INavigationSection[],
		sectionName: string, primaryRoute: string, opportunityId: string) {

		const model = new OpportunityDocumentSecurityModel();
		model.currentUser = JSON.parse(sessionStorage.getItem(this.currentUser)) as UserModel;
		model.sectionIndex = navigationSections.findIndex(x => x.displayText == sectionName);

		if (model.sectionIndex == -1 || model.currentUser.SuperWriter) {
			return;
		}

		model.uploadedDocsSecurityObjects = uploadedDocsSecurityObjects;
		model.electronicDocsSecurityObjects = electronicDocsSecurityObjects;
		model.navigationSections = navigationSections;
		model.primaryRoute = primaryRoute;
		model.opportunityId = opportunityId;

		this.setDocumentSecurity(model);
	}

	private setDocumentSecurity(model: OpportunityDocumentSecurityModel) {
		model.uploadedDocWriteAccessExists = model.uploadedDocsSecurityObjects.find(x => x.DeniedWriteAccess == 0) != null;
		model.electronicDocsWriteAccessExists = model.electronicDocsSecurityObjects.find(x => x.DeniedWriteAccess == 0) != null;
		model.uploadedDocReadAccessExists = model.uploadedDocsSecurityObjects.find(x => x.DeniedReadAccess == 0) != null;
		model.electronicDocsReadAccessExists = model.electronicDocsSecurityObjects.find(x => x.DeniedReadAccess == 0) != null;
		model.bothWriteAccessExists = model.uploadedDocWriteAccessExists && model.electronicDocsWriteAccessExists;
		model.bothReadAccessExists = model.uploadedDocReadAccessExists && model.electronicDocsReadAccessExists;
		this.setDocumentSection(model);
	}

	private setDocumentSection(model: OpportunityDocumentSecurityModel) {

		if (this.bothReadOrOneWriteAccess(model)) {
			const documentsection = this.getBothReadOrOneWriteAccessSection(model);
			this.updateSection(model, documentsection);
		}
		else if (this.oneSectionWithAllAcceess(model)) {
			const documentsection = this.getOneSectionWithAllAcceess(model);
			this.updateSection(model, documentsection);
		}
		else if (this.oneSectionWithReadOnlyAccess(model)) {
			const documentsection = this.getOneSectionWithReadOnlyAccess(model);
			this.updateSection(model, documentsection);
		}
		else if (this.allDocumentWithReadonly(model)) {
			const documentsection = 'readonlydocuments';
			this.updateSection(model, documentsection);
		}
		else if (!model.currentUser.SuperReader && !model.bothReadAccessExists) {
			model.navigationSections.splice(model.sectionIndex, 1);
		}

	}

	private getOneSectionWithReadOnlyAccess(model: OpportunityDocumentSecurityModel): string {
		return model.uploadedDocReadAccessExists ? 'uploadedreadonlydocuments' : 'esignreadonlydocuments';
	}

	private getOneSectionWithAllAcceess(model: OpportunityDocumentSecurityModel): string {
		return model.uploadedDocWriteAccessExists ? 'uploadeddocuments' : 'esigndocuments';
	}

	private getBothReadOrOneWriteAccessSection(model: OpportunityDocumentSecurityModel): string {
		return model.uploadedDocWriteAccessExists ? 'uploadedorreadonlyesigndocuments' : 'esignorreadonlydocuments';
	}

	private allDocumentWithReadonly(model: OpportunityDocumentSecurityModel) {
		return !model.bothWriteAccessExists && model.bothReadAccessExists;
	}

	private oneSectionWithReadOnlyAccess(model: OpportunityDocumentSecurityModel) {
		return !model.bothWriteAccessExists && !model.bothReadAccessExists
			&& (model.uploadedDocReadAccessExists || model.electronicDocsReadAccessExists);
	}

	private oneSectionWithAllAcceess(model: OpportunityDocumentSecurityModel) {
		return !model.bothWriteAccessExists && !model.bothReadAccessExists
			&& (model.uploadedDocWriteAccessExists || model.electronicDocsWriteAccessExists);
	}

	private bothReadOrOneWriteAccess(model: OpportunityDocumentSecurityModel) {
		return !model.bothWriteAccessExists && model.bothReadAccessExists
			&& (model.uploadedDocWriteAccessExists || model.electronicDocsWriteAccessExists);
	}

	private updateSection(model: OpportunityDocumentSecurityModel, documentsection: string) {
		if (!AppFunctions.IsNullorUndefined(documentsection)) {
			const authRoute = <AuthRoute>{ primary: model.primaryRoute, section: `${documentsection}/${model.opportunityId}` };
			model.navigationSections[model.sectionIndex].route = authRoute;
		}
	}

	public setControlsReadOnly(controlList) {
		this.setControlEditability(controlList, true);
	}

	public setControlEditability(controlList, isReadOnly: boolean) {
		for (const key in controlList) {
			if (!controlList.hasOwnProperty(key)) continue;

			let controlObject = controlList[key];

			controlObject["readonly"] = isReadOnly;
			controlObject["disabled"] = isReadOnly;

			if (controlObject.hasOwnProperty("data")) {
				controlObject.data["disabled"] = isReadOnly;
			}
		}
	}

	/**
	 * @returns
	 */
	public CheckReadWriteDelete(params: {
		dbStyleRowSecurity?: IDbStyleRowSecurity,
		rowSecurity?: IRowSecurity,
		securityObjectsAndBhCode?: SecurityObjectsAndBhCode,
	}): Permissions {
		const currentUser = this.baseService.parsedCurrentUser;
		const securityObjectsAndBhCode = params.securityObjectsAndBhCode;

		if (!!params.dbStyleRowSecurity && !!params.rowSecurity) {
			throw "Only one style of row security object can be passed.";
		}

		let rowSecurity: IDbStyleRowSecurity;

		if (!!params.dbStyleRowSecurity) {
			rowSecurity = params.dbStyleRowSecurity;
		}

		if (!!params.rowSecurity) {
			rowSecurity = new DbStyleRowSecurityModel();
			rowSecurity.DeniedDeleteAccess = !params.rowSecurity.CanDelete;
			rowSecurity.DeniedReadAccess = !params.rowSecurity.CanRead;
			rowSecurity.DeniedWriteAccess = !params.rowSecurity.CanWrite;
			rowSecurity.Id = params.rowSecurity.Id;
		}

		let permissions = new Permissions();

		if (currentUser.IsBHCAdmin) {
			permissions.CanRead = true;
			permissions.CanWrite = true;
			permissions.CanDelete = true;
			permissions.HasSuperWriteAccess = true;
			permissions.HasSuperAllAccess = true;
			return permissions;
		}

		// Although SuperReader should override everything BHCAdmin (incorrectly) 
		// has both SuperReader and SuperWriter so that gets checked first above.
		if (currentUser.SuperReader) {
			permissions.CanRead = true;
			return permissions;
		}

		if (currentUser.SuperWriter) {
			permissions.CanRead = true;
			permissions.CanWrite = true;
			permissions.CanDelete = true;
			permissions.HasSuperWriteAccess = true;
			return permissions;
		}

		if (rowSecurity != undefined && rowSecurity != null) {
			if (rowSecurity.DeniedReadAccess == false) {
				permissions.CanRead = true;
			}
			if (rowSecurity.DeniedWriteAccess == false) {
				permissions.CanWrite = true;
			}
			if (rowSecurity.DeniedDeleteAccess == false) {
				permissions.CanDelete = true;
			}
		}

		if (securityObjectsAndBhCode != undefined && securityObjectsAndBhCode != null) {
			const securityObjectPermissions = this.CheckSecurityObjectsAndBhCode(securityObjectsAndBhCode);
			permissions = this.MergePermissions(permissions, securityObjectPermissions);
		}

		return permissions;
	}

	public CheckSecurityObjectsAndBhCode(securityObjectsAndBhCode: SecurityObjectsAndBhCode): Permissions {
		const permissions = new Permissions();

		var filteredUserSecurityData = securityObjectsAndBhCode.SecurityObjects.filter(x => x.BhCode.toUpperCase() === securityObjectsAndBhCode.BhCode);
		if (filteredUserSecurityData.length > 0) {
			if (filteredUserSecurityData[0].DeniedReadAccess == 0) {
				permissions.CanRead = true
			}
			if (filteredUserSecurityData[0].DeniedWriteAccess == 0) {
				permissions.CanWrite = true;
			}
			if (filteredUserSecurityData[0].DeniedDeleteAccess == 0) {
				permissions.CanDelete = true;
			}
		}

		return permissions;
	}

	/**
	 * Merge permissively (OR rather than AND)
	 * @param permissions1
	 * @param permissions2
	 * @returns
	 */
	public MergePermissions(permissions1: Permissions, permissions2: Permissions): Permissions {
		const permissions = new Permissions();
		permissions.CanDelete = permissions1.CanDelete || permissions2.CanDelete;
		permissions.CanRead = permissions1.CanRead || permissions2.CanRead;
		permissions.CanWrite = permissions1.CanWrite || permissions2.CanWrite;
		permissions.HasSuperAllAccess = permissions1.HasSuperAllAccess || permissions2.HasSuperAllAccess;
		return permissions;
	}

}

export class Permissions {
	public CanRead: boolean = false;
	public CanWrite: boolean = false;
	public CanDelete: boolean = false;
	public HasSuperAllAccess: boolean = false;
	public HasSuperWriteAccess: boolean = false;
}

class SecurityObjectsAndBhCode {
	public SecurityObjects: SecurityObject[];
	public BhCode: string;
}
