import { ErrorHandler, Injectable } from '@angular/core';
import {
    ApplicationInsights,
    IAppInsights,
    IAutoExceptionTelemetry,
    IDependencyTelemetry,
    IEventTelemetry,
    IExceptionTelemetry,
    IMetricTelemetry, IPageViewPerformanceTelemetry,
    IPageViewTelemetry, ITelemetryItem,
    ITraceTelemetry
} from "@microsoft/applicationinsights-web";
import { ICookieMgr } from "@microsoft/applicationinsights-core-js";
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { AppSettings } from '../../app.globals';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppInsightsService implements IAppInsights {
    private readonly context: ApplicationInsights;

    public static instance: ApplicationInsights;

    public get isConfigured(): boolean {
        return !!this.context;
    }

    public get isInitialized(): boolean {
        return !!this.context;
    }

    constructor(private router: Router) {
        const connectionString = AppSettings.APP_INSIGHTS_CONNECTION_STRING;
        if (connectionString) {
            try {
                const angularPlugin = new AngularPlugin();
                this.context = new ApplicationInsights({
                    config: {
                        connectionString: connectionString,
                        extensions: [<any>angularPlugin],
                        enableAutoRouteTracking: true,
                        extensionConfig: {
                            [angularPlugin.identifier]: {
                                router: this.router,
                                errorServices: [new ErrorHandler()],
                            }
                        }
                    }
                });
                this.context.loadAppInsights();
                this.context.trackPageView();
                console.info('application insights up and running');
            } catch (e) {
                this.context = null;
                console.error('Application Insights failed to load', 'connectionString =', `"${connectionString}"`, e);
            }
        }
    }
    setUserId(userId: string) {
        this.context?.setAuthenticatedUserContext(userId);
    }
    clearUserId() {
        this.context?.clearAuthenticatedUserContext();
    }
    logPageView(name?: string, uri?: string) {
        this.context?.trackPageView({
            name: name,
            uri: uri
        });
    }
    logPageView1(name: string, average: number, properties?: { [key: string]: any }) {
        this.context?.trackPageView({
            name: "My Page View",
            properties: {
                ServiceName: "My Service"
            }
        });
    }
    logEvent(name: string, properties?: { [key: string]: any }) {
        this.context?.trackEvent({ name: name }, properties);

    }
    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.context?.trackMetric({ name: name, average: average }, properties);

    }
    logException(exception: Error, severityLevel?: number) {
        this.context?.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.context?.trackTrace({ message: message }, properties);
    }


    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackevent
    // trackEvent(name: string, properties?: {[string]:string}, measurements?: {[string]:number})
    // Log a user action or other occurrence.
    trackEvent(eventName: IEventTelemetry, properties?: { [name: string]: string }) {
        try {
            this.context?.trackEvent(eventName, properties);
        } catch (ex) {
            console.warn('Angular application insights Error [trackEvent]: ', ex);
        }
    }

    startTrackEvent(name: string): any {
        try {
            this.context?.startTrackEvent(name);
        } catch (ex) {
            console.warn('Angular application insights Error [startTrackEvent]: ', ex);
        }
    }

    stopTrackEvent(name: string, properties?: { [name: string]: string }, measurements?: {
        [name: string]: number
    }): any {
        try {
            this.context?.stopTrackEvent(name, properties, measurements);
        } catch (ex) {
            console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackpageview
    // trackPageView(name?: string, url?: string, properties?: {[string]:string}, measurements?: {[string]:number}, duration?: number)
    // Logs that a page or similar container was displayed to the user.
    trackPageView(name?: IPageViewTelemetry) {
        try {
            this.context?.trackPageView(name);
        } catch (ex) {
            console.warn('Angular application insights Error [trackPageView]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#starttrackpage
    // startTrackPage(name?: string)
    // Starts the timer for tracking a page view. Use this instead of trackPageView if you want to control when the
    // page view timer starts and stops, but don't want to calculate the duration yourself. This method doesn't send any
    // telemetry. Call stopTrackPage to log the end of the page view and send the event.
    startTrackPage(name?: string) {
        try {
            this.context?.startTrackPage(name);
        } catch (ex) {
            console.warn('Angular application insights Error [startTrackPage]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#stoptrackpage
    // stopTrackPage(name?: string, url?: string, properties?: {[string]:string}, measurements?: {[string]:number})
    // Stops the timer that was started by calling startTrackPage and sends the page view telemetry with the
    // specified properties and measurements. The duration of the page view will be the time between calling startTrackPage and stopTrackPage.
    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: {
        [name: string]: number
    }) {
        try {
            this.context?.stopTrackPage(name, url, properties, measurements);
        } catch (ex) {
            console.warn('Angular application insights Error [stopTrackPage]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackmetric
    // trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: {[string]:string})
    // Log a positive numeric value that is not associated with a specific event.
    // Typically used to send regular reports of performance indicators.
    trackMetric(name: IMetricTelemetry, properties?: {
        [name: string]: string
    }) {
        try {
            this.context?.trackMetric(name, properties);
        } catch (ex) {
            console.warn('Angular application insights Error [trackTrace]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
    // trackException(exception: Error, handledAt?: string, properties?: {[string]:string}, measurements?: {[string]:number}, severityLevel?: AI.SeverityLevel)
    // Log an exception you have caught. (Exceptions caught by the browser are also logged.)
    trackException(exception: IExceptionTelemetry, properties?: { [name: string]: string }) {
        try {
            this.context?.trackException(exception, properties);
        } catch (ex) {
            console.warn('Angular application insights Error [trackException]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#tracktrace
    // trackTrace(message: string, properties?: {[string]:string}, severityLevel?: AI.SeverityLevel)
    // Log a diagnostic event such as entering or leaving a method.
    trackTrace(message: ITraceTelemetry, properties?: { [name: string]: string }) {
        try {
            this.context?.trackTrace(message, properties);
        } catch (ex) {
            console.warn('Angular application insights Error [trackTrace]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackdependency
    // trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number)
    // Log a dependency call (for instance: ajax)
    trackDependency(data: IDependencyTelemetry) {
        try {
            this.context?.trackDependencyData(data);
        } catch (ex) {
            console.warn('Angular application insights Error [trackDependency]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#flush
    // flush()
    // Immediately send all queued telemetry. Synchronous.
    // * You don't usually have to use this, as it happens automatically on window closing.
    flush() {
        try {
            this.context?.flush();
        } catch (ex) {
            console.warn('Angular application insights Error [flush]: ', ex);
        }

    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
    // setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string)
    // Set the authenticated user id and the account id in this session. Use this when you have identified a specific
    // signed-in user. Parameters must not contain spaces or ,;=|
    /**
     * Sets the authenticated user id and the account id.
     * User auth id and account id should be of type string. They should not contain commas, semi-colons, equal signs, spaces, or vertical-bars.
     *
     * By default the method will only set the authUserID and accountId for all events in this page view. To add them to all events within
     * the whole session, you should either call this method on every page view or set `storeInCookie = true`.
     *
     * @param authenticatedUserId {string} - The authenticated user id. A unique and persistent string that represents each authenticated user in the service.
     * @param accountId {string} - An optional string to represent the account associated with the authenticated user.
     * @param storeInCookie {boolean} - AuthenticateUserID will be stored in a cookie and added to all events within this session.
     */
    setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie: boolean = false) {
        try {
            this.context?.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
        } catch (ex) {
            console.warn('Angular application insights Error [setAuthenticatedUserContext]: ', ex);
        }
    }

    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#clearauthenticatedusercontext
    // clearAuthenticatedUserContext ()
    // Clears the authenticated user id and the account id from the user context, and clears the associated cookie.
    clearAuthenticatedUserContext() {
        try {
            this.context?.clearAuthenticatedUserContext();
        } catch (ex) {
            console.warn('Angular application insights Error [clearAuthenticatedUserContext]: ', ex);
        }
    }

    _onerror(tel: IAutoExceptionTelemetry): any {
        console.warn('Angular application insights Error [_onerror]: ', tel?.message);
    }

    init(): void {
        if (!this.context) {
            console.warn('A ConnectingString value is required to initialize AppInsightsService.');
            return;
        }
    }

    addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => (boolean | void)): void {
        this.context?.addTelemetryInitializer(telemetryInitializer);
    }

    getCookieMgr(): ICookieMgr {
        return this.context?.getCookieMgr();
    }

    trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry, customProperties?: {
        [p: string]: any
    }): void {
        this.context?.trackPageViewPerformance(pageViewPerformance);
    }
}
