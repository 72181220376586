import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NotificationComponent} from './components/notification.component';
import {Ng2DatetimePickerModule} from './controls/ng2-datetime-picker/index';
import {CheckboxRComponent} from './controls/reactive/bh-r-checkbox.component';
import {DateRComponent} from './controls/reactive/bh-r-date.component';
import {DropDownRComponent} from './controls/reactive/bh-r-dropdown.component';
import {InputRComponent} from './controls/reactive/bh-r-input.component';
import {LoadingRComponent} from './controls/reactive/bh-r-loading.component';
import {ModalRComponent} from './controls/reactive/bh-r-modal.component';
import {TextareaRComponent} from './controls/reactive/bh-r-textarea.component';
import {BhRDropdownNewComponent} from "./controls/reactive/bh-r-dropdown-new.component";
import {FormatPipe} from "./pipes/format.pipe";
import {ExceptLastPipe} from "../client-portal/auth/applications/application/pipes/except-last.pipe";
import {ClickOutsideDirective} from "./directive/click-outside-close";
import {ParsedInputRComponent} from "./controls/reactive/bh-r-parsed-input.component";
import {FileDropComponent} from "./controls/reactive/bh-file-drop.component";
import {LoadingIndicator} from "./controls/template/bh-loading-indicator.component";
import {MultiSelectDropDownRComponent} from "./controls/reactive/bh-r-multiselect-dropdown.component";
import {WjInputModule} from "@grapecity/wijmo.angular2.input";
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule,
        Ng2DatetimePickerModule,
        // PrimeNG
        EditorModule,
        WjInputModule,
        LoadingRComponent
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule,
        FormatPipe,
        NotificationComponent,
        DropDownRComponent,
        ParsedInputRComponent,
        BhRDropdownNewComponent,
        CheckboxRComponent,
        DateRComponent,
        InputRComponent,
        LoadingRComponent,
        ModalRComponent,
        TextareaRComponent,
        FormatPipe,
        ExceptLastPipe,
        ClickOutsideDirective,
        FileDropComponent,
        LoadingIndicator,
        MultiSelectDropDownRComponent
    ],
    declarations: [
        NotificationComponent,
        FormatPipe,
        DropDownRComponent,
        ClickOutsideDirective,
        BhRDropdownNewComponent,
        CheckboxRComponent,
        DateRComponent,
        InputRComponent,
        ParsedInputRComponent,
        ModalRComponent,
        TextareaRComponent,
        FormatPipe,
        ExceptLastPipe,
        FileDropComponent,
        LoadingIndicator,
        MultiSelectDropDownRComponent
    ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ]
})
export class BasicSharedModule {
}
